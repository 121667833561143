<template>
  <div>
    <CustomScrollbar
      :settings="state.scrollbarSettings"
      scroll-component-class="main-scroll-container"
      class="w-100"
    >
      <CContainer class="v3kiosk-view py-4 full-height-scrooll-content">
        <CRow class="d-flex justify-content-center p-sm-5 p-md-0 w-100">
          <CCol
            col="8"
            sm="10"
            md="9"
            lg="8"
            xl="7"
            class="v3kiosk-body m-auto p-4 text-center py-5"
          >
            <CRow class="kiosk-mode-top m-auto">
              <CCol class="text-start p-0">
                <img
                  src="@/assets/images/securlypass-color-logo.png"
                  alt="securlypass"
                  class="w-75"
              /></CCol>
              <CCol class="kiosk-mode-text text-end p-0">
                <div>Kiosk Mode</div>
              </CCol>
            </CRow>
            <div v-if="activeKiosk">
              <div v-show="!state.showActivePass" class="text-center">
                <div>
                  <MainScreen
                    v-if="showMainScreen"
                    :active-kiosk="activeKiosk"
                    @tab-changed="setActiveScreen"
                  />
                  <CreatePassForm
                    v-if="createPassScreen"
                    ref="createPassForm"
                    @tab-changed="setActiveScreen"
                    @kiosk-pass-created="
                      setActiveScreen('activePass'),
                        (state.showActivePass = true)
                    "
                  />
                  <EndPassForm
                    v-if="endPassScreen"
                    @cancel="setActiveScreen"
                    @tab-changed="setActiveScreen"
                  />
                  <KioskUserLogin
                    v-if="loginScreen"
                    :active-kiosk="activeKiosk"
                    @tab-changed="setActiveScreen"
                    @user-logged-in="onUserLogin()"
                  />
                </div>
              </div>
              <div v-if="state.showActivePass">
                <KioskActivePass
                  v-if="activePassScreen"
                  @cancel="closeActivePassPopup"
                />
              </div>
            </div>
            <div v-else>No active kiosk.</div>
          </CCol>
        </CRow>
      </CContainer>
    </CustomScrollbar>
    <CustomModal
      v-if="modal"
      v-bind="modal.options"
      @close="closeModal"
    ></CustomModal>
  </div>
</template>
<script>
import CreatePassForm from "@/v3components/Forms/KioskForms/CreatePassForm"
import EndPassForm from "@/v3components/Forms/KioskForms/EndPassForm"
import KioskActivePass from "@/v3components/Forms/KioskForms/KioskActivePass"
import MainScreen from "@/v3components/Forms/KioskForms/MainScreen"
import KioskUserLogin from "@/v3components/Forms/KioskForms/KioskUserLogin"
import { useStore } from "vuex"
import { onMounted, computed, reactive, ref, inject } from "vue"
import { useRouter } from "vue-router"
import kioskMode from "@/events/kioskMode/index"

export default {
  name: "KioskLaunch",
  components: {
    KioskActivePass,
    EndPassForm,
    CreatePassForm,
    MainScreen,
    KioskUserLogin
  },
  unmounted() {
    this.$store.dispatch("kiosks/userLogout")
  },
  setup() {
    const modal = inject("modal")

    const store = useStore()

    const route = useRouter()

    const state = reactive({
      showActivePass: false,
      kioskFlow: {
        mainScreen: true,
        createPass: false,
        endPass: false,
        userLogin: false,
        activePass: false
      },
      scrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: true
      }
    })

    const createPassForm = ref(null)

    const activeKiosk = computed(() => store.getters["kiosks/activeKiosk"])
    const activePass = computed(() => store.getters["kiosks/activePass"])
    const access_token = computed(() => store.getters["kiosks/accessToken"])
    const createNewPassData = computed(
      () => store.getters["kiosks/createNewPassData"]
    )

    const showMainScreen = computed(() => {
      return state.kioskFlow.mainScreen && !access_token.value
    })
    const createPassScreen = computed(() => {
      return (
        (state.kioskFlow.createPass && !access_token.value) ||
        (state.kioskFlow.createPass && createNewPassData.value)
      )
    })
    const endPassScreen = computed(() => {
      return !!(state.kioskFlow.endPass && access_token.value)
    })
    const loginScreen = computed(() => {
      return (
        (state.kioskFlow.userLogin && !access_token.value) ||
        (!endPassScreen.value && state.kioskFlow.endPass)
      )
    })
    const activePassScreen = computed(() => {
      return state.kioskFlow.activePass && access_token.value
    })

    onMounted(() => {
      const directLoginToken = localStorage.getItem("kioskDirectLoginToken")
      initSockets()

      if (directLoginToken && !activeKiosk.value) {
        store
          .dispatch("kiosks/directLogin", { passcode: directLoginToken })
          .then((response) => {
            const data = response.data.data
            if (data) {
              store.commit("kiosks/SET_ACTIVE_KIOSK", data)
              kioskMode.initKioskPreferencesEvents()
              if (data.favorites && data.favorites.length) {
                const fav = JSON.parse(JSON.stringify(data.favorites))
                const favs = fav.sort((a, b) => {
                  return a.position - b.position
                })
                store.commit("favorites/SET_FAVORITES", favs)
              }
            }
          })
      } else {
        store.commit("passes/SET_ACTIVE_PASS", null)
        if (!activeKiosk.value) {
          route.push("/kiosk/login")
        }
        kioskMode.initKioskPreferencesEvents()
      }
    })

    const setActiveScreen = (screen) => {
      if (Object.prototype.hasOwnProperty.call(state.kioskFlow, screen)) {
        for (const key in state.kioskFlow) {
          if (Object.hasOwnProperty.call(state.kioskFlow, key)) {
            state.kioskFlow[key] = key === screen
          }
        }
      }
    }

    const closeActivePassPopup = () => {
      store.dispatch("kiosks/userLogout")
      state.showActivePass = false
      setActiveScreen("mainScreen")
    }

    const onUserLogin = () => {
      if (!state.kioskFlow.endPass && createNewPassData.value) {
        setActiveScreen("createPass")
        if (!activePass.value) {
          // state.showActivePass = true
          setTimeout(() => {
            createPassForm.value.createPass(createNewPassData.value)
          }, 100)
        }
      }
    }

    const closeModal = () => {
      modal.close()
    }

    const initSockets = () => {
      kioskMode.initKioskEvents()
    }

    return {
      modal,
      closeModal,
      state,
      activeKiosk,
      activePass,
      access_token,
      createNewPassData,
      showMainScreen,
      createPassScreen,
      endPassScreen,
      loginScreen,
      setActiveScreen,
      closeActivePassPopup,
      onUserLogin,
      createPassForm,
      activePassScreen
    }
  },
  beforeRouteLeave(to, from, next) {
    next(false)
  }
}
</script>
